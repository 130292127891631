let markers = [];
export let map;
let mode = 'all';
let searchParams = null;
window.loadBicyclesDataTimeout = null;
let allBicycles = [];
let lastBounds = null;
let activeMarker = null;

function setupAutocomplete() {
  const input = document.getElementById('city-input');
  if (!input) return;

  const autocomplete = new google.maps.places.Autocomplete(input, { types: ['(cities)'] });
  autocomplete.bindTo('bounds', map);

  autocomplete.addListener('place_changed', function() {
    const place = autocomplete.getPlace();
    if (!place.geometry) {
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }

    if (place.geometry.viewport) {
      const expandedBounds = expandBounds(place.geometry.viewport, 10000); // 10 km buffer
      map.fitBounds(expandedBounds);
    } else {
      const latLng = place.geometry.location;
      const expandedBounds = expandBoundsByLatLng(latLng, 10000); // 10 km buffer around the location
      map.fitBounds(expandedBounds);
      map.setZoom(12); // Adjust zoom level appropriately
    }

    const loadMoreButton = document.getElementById('load-more');
    if (loadMoreButton) {
      loadMoreButton.setAttribute('data-page', 2);
    }
    resetAndShowLoadMoreButton();
    loadBicyclesData();
  });
}

function resetAndShowLoadMoreButton() {
  const loadMoreButton = document.getElementById('load-more');
  if (loadMoreButton) {
    loadMoreButton.setAttribute('data-page', 2); // Réinitialiser la pagination à la première page
    loadMoreButton.style.display = 'block'; // Réafficher le bouton
  }
}

function expandBounds(bounds, distance) {
  // Convert distance from meters to latitude/longitude degrees
  const latChange = distance / 111320;
  const lngChange = distance / (111320 * Math.cos(Math.PI * bounds.getCenter().lat() / 180));

  const sw = new google.maps.LatLng(bounds.getSouthWest().lat() - latChange, bounds.getSouthWest().lng() - lngChange);
  const ne = new google.maps.LatLng(bounds.getNorthEast().lat() + latChange, bounds.getNorthEast().lng() + lngChange);

  return new google.maps.LatLngBounds(sw, ne);
}

function expandBoundsByLatLng(latLng, distance) {
  const latChange = distance / 111320;
  const lngChange = distance / (111320 * Math.cos(Math.PI * latLng.lat() / 180));

  const sw = new google.maps.LatLng(latLng.lat() - latChange, latLng.lng() - lngChange);
  const ne = new google.maps.LatLng(latLng.lat() + latChange, latLng.lng() + lngChange);

  return new google.maps.LatLngBounds(sw, ne);
}

function loadMarkers(bicycles) {
  const markersMap = {}; // Object to store markers by position
  const radius = 0.0018; // Approximate radius in degrees for 10 meters

  // Clear existing markers from the map
  markers.forEach(marker => {
    marker.setMap(null);
  });
  markers = []; // Reset the markers array

  let infowindow = new google.maps.InfoWindow();

  bicycles.forEach(bicycle => {
    const position = { lat: bicycle.latitude, lng: bicycle.longitude };
    // Round the position to group nearby locations
    const roundedPosition = {
      lat: Math.round(position.lat / radius) * radius,
      lng: Math.round(position.lng / radius) * radius
    };
    const key = `${roundedPosition.lat},${roundedPosition.lng}`;

    // Initialize marker array for this position if not already
    if (!markersMap[key]) {
      markersMap[key] = [];
    }
    markersMap[key].push(bicycle);

    const numberOfBicycles = markersMap[key].length;

    // Find existing marker for this rounded position
    const existingMarker = markers.find(marker =>
      Math.round(marker.getPosition().lat() / radius) * radius === roundedPosition.lat &&
      Math.round(marker.getPosition().lng() / radius) * radius === roundedPosition.lng
    );

    if (!existingMarker) {
      const marker = new google.maps.Marker({
        position: roundedPosition,
        map: map,
        title: numberOfBicycles > 1 ? `${numberOfBicycles}` : bicycle.model,
        label: numberOfBicycles > 1 ? {
          text: numberOfBicycles.toString(),
          color: 'black',
          fontSize: '20px',
          fontWeight: 'bold'
        } : undefined,
        icon: {
          url: '/assets/logo_maps.png',
          scaledSize: new google.maps.Size(22.8, 35)
        }
      });

      marker.addListener('click', () => {
        if (activeMarker) {
          activeMarker.setIcon({
            url: '/assets/logo_maps.png',
            scaledSize: new google.maps.Size(22.8, 35)
          });
        }

        marker.setIcon({
          url: '/assets/logo_maps_beige.png',
          scaledSize: new google.maps.Size(22.8, 35)
        });
        activeMarker = marker;

        infowindow.close();

        const bicyclesAtPosition = markersMap[key];
        let contentString = '';

        if (bicyclesAtPosition.length > 1) {
          const bike = bicyclesAtPosition[0];
          const card = document.querySelector(`.annonce-item[data-bicycle-id="${bike.id}"]`);
          const pricePerHourWithFees = card ? card.getAttribute('data-price-per-hour-with-fees') : bike.price_per_hour;
          const pricePerWeekWithFees = card ? card.getAttribute('data-price-per-week-with-fees') : bike.price_1_week;

          contentString = `
          <div style="height: auto; border-radius: 10px; overflow: hidden; position: relative; background-color: white; box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);">
            <!-- Carousel pour plusieurs vélos -->
            <div id="carouselAdsBicycles" class="carousel slide" style="height: 70%; overflow: hidden;">
              <div class="carousel-inner" style="border-radius:0px;">
                ${bicyclesAtPosition.map((bike, index) => {
                  return `
                  <div class="carousel-item ${index === 0 ? 'active' : ''}" style="height: 100%;">
                    <img src="${bike.image_url || '/assets/icone_velo.png'}" class="d-block w-100" style="object-fit: cover; width: 190px; height: 130px; border-radius: 10px;" alt="${bike.model}">
                  </div>
                `;}).join('')}
              </div>
              <div class="carousel-indicators" style="bottom: -15px; text-align: center;">
                ${bicyclesAtPosition.map((_, index) => `
                  <button type="button" 
                          data-bs-target="#carouselAdsBicycles" 
                          data-bs-slide-to="${index}" 
                          class="${index === 0 ? 'active' : ''}" 
                          aria-current="true" 
                          aria-label="Slide ${index + 1}" 
                          style="border-radius: 50%; width: 10px; height: 10px; 
                                border: none; padding: 0; margin: 0 2px;">
                  </button>
                `).join('')}
              </div>
              <button class="carousel-control-prev" style="border-radius: 0px; height: 30px; width: 30px; position: absolute; top: 50%; transform: translateY(-50%); left: 5px;" type="button" data-bs-target="#carouselAdsBicycles" data-bs-slide="prev">
                <span class="carousel-control-prev-icon white-arrow" aria-hidden="true"></span>
              </button>
              <button class="carousel-control-next" style="border-radius: 0px; height: 30px; width: 30px; position: absolute; top: 50%; transform: translateY(-50%); right: 5px;" type="button" data-bs-target="#carouselAdsBicycles" data-bs-slide="next">
                <span class="carousel-control-next-icon white-arrow-next" aria-hidden="true"></span>
              </button>
            </div>
            <!-- Section d'informations (30%) -->
            <div style="height: 30%; padding: 10px; background-color: white; box-sizing: border-box;">
              <h5 id="bicycle-model" style="margin: 0; font-size: 16px;">${bicyclesAtPosition[0].model}</h5>
              <p id="bicycle-location" style="margin: 2px 0;">${bicyclesAtPosition[0].city}, ${bicyclesAtPosition[0].postal_code}</p>
              <div style="display: flex; justify-content: space-between; align-items: center;">
                <p id="bicycle-price_day" style="margin: 2px 0;"><b>${pricePerHourWithFees} &euro;</b> / jour</p>
                <p id="bicycle-price_week" style="margin: 2px 0; margin-left: 10px;"><b>${pricePerWeekWithFees} &euro;</b> / sem</p>
              </div>
              <a id="bicycle-link" href="/bicycles/${bicyclesAtPosition[0].slug}" class="btn btn-secondary btn-sm d-flex justify-content-center" style="margin-bottom: 0px;" aria-label="Voir les détails du vélo" data-turbo="false">Voir</a>
            </div>
            <button class="close-infowindow" style="position: absolute; top: 5px; right: 5px; border: none; background: none; font-size: 20px; cursor: pointer;">&times;</button>
          </div>
        `;

        infowindow.setContent(contentString);
        infowindow.open({ anchor: marker, map: map, shouldFocus: false });

        setTimeout(() => {
          const carousel = document.getElementById('carouselAdsBicycles');
          if (carousel) {
            console.log('Carrousel trouvé');
            carousel.addEventListener('slide.bs.carousel', function (event) {
              const index = event.to; // Get index of the new slide
              const activeBike = bicyclesAtPosition[index];
              const activeCard = document.querySelector(`.annonce-item[data-bicycle-id="${activeBike.id}"]`);
              const activePricePerHourWithFees = activeCard ? activeCard.getAttribute('data-price-per-hour-with-fees') : activeBike.price_per_hour;
              const activePricePerWeekWithFees = activeCard ? activeCard.getAttribute('data-price-per-week-with-fees') : activeBike.price_1_week;
        
              // Mettre à jour les informations en fonction du slide actif
              document.getElementById('bicycle-model').textContent = activeBike.model;
              document.getElementById('bicycle-location').textContent = `${activeBike.city}, ${activeBike.postal_code}`;
              document.getElementById('bicycle-price_day').innerHTML = `<b>${activePricePerHourWithFees} &euro;</b> / jour`;
              document.getElementById('bicycle-price_week').innerHTML = `<b>${activePricePerWeekWithFees} &euro;</b> / sem`;
              document.getElementById('bicycle-link').setAttribute('href', `/bicycles/${activeBike.slug}`);
            });
          } else {
            console.warn("Le carrousel n'a pas été trouvé");
          }
        }, 100); // Ajuste la valeur de timeout si nécessaire

        } else {
          // Single bicycle, use image with overlay
          const bike = bicyclesAtPosition[0];
          const card = document.querySelector(`.annonce-item[data-bicycle-id="${bike.id}"]`);
          const pricePerHourWithFees = card ? card.getAttribute('data-price-per-hour-with-fees') : bike.price_per_hour;
          const pricePerWeekWithFees = card ? card.getAttribute('data-price-per-week-with-fees') : bike.price_1_week;

          contentString = `
            <div style="height: auto; border-radius: 10px; overflow: hidden; position: relative; background-color: white; box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);">
              <!-- Image covering 70% -->
              <div style="height: 70%; overflow: hidden;">
                <img src="${bike.image_url || '/assets/icone_velo.png'}" class="d-block w-100" style="object-fit: cover; width: 190px; height: 130px; border-radius: 10px;" alt="${bike.model}">
              </div>
              <!-- Info section (30%) -->
              <div style="height: 30%; padding: 10px; background-color: white; box-sizing: border-box;">
                <h5 style="margin: 0; font-size: 16px;">${bike.model}</h5>
                <p style="margin: 2px 0;">${bike.city}, ${bike.postal_code}</p>
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <p style="margin: 2px 0;"><b>${pricePerHourWithFees} &euro;</b> / jour</p>
                  <p style="margin: 2px 0;"><b>${pricePerWeekWithFees} &euro;</b> / sem</p>
                </div>  
                <a href="/bicycles/${bike.slug}" class="btn btn-secondary btn-sm d-flex justify-content-center" style="margin-bottom: 0px;" aria-label="Voir les détails du vélo" data-turbo="false">Voir</a>
              </div>
              <button class="close-infowindow" style="position: absolute; top: 5px; right: 5px; border: none; background: none; font-size: 20px; cursor: pointer;">&times;</button>
            </div>
          `;
        }

        infowindow.setContent(contentString);
        infowindow.open({ anchor: marker, map: map, shouldFocus: false });

        setTimeout(() => {
          const closeButton = document.querySelector('.close-infowindow');
          if (closeButton) {
            closeButton.addEventListener('click', () => {
              infowindow.close();
              if (activeMarker) {
                activeMarker.setIcon({
                  url: '/assets/logo_maps.png',
                  scaledSize: new google.maps.Size(22.8, 35)
                });
                activeMarker = null;
              }
            });
          }
        }, 100);

        google.maps.event.clearListeners(map, 'bounds_changed');
        setTimeout(() => {
          map.addListener('bounds_changed', debounce(handleBoundsChanged, 500));
        }, 1000);
      });

      markers.push(marker);
    } else {
      existingMarker.setLabel({
        text: numberOfBicycles.toString(),
        color: 'black',
        fontSize: '12px',
        fontWeight: 'bold'
      });
    }
  });  
   
    map.addListener('click', () => {
      infowindow.close();
      if (activeMarker) {
        activeMarker.setIcon({
          url: '/assets/logo_maps.png',
          scaledSize: new google.maps.Size(22.8, 35)
        });
        activeMarker = null;
      }
  });
}

export function initIndexMap() {
  console.log("Initializing Index Map");

  if (markers.length > 0) {
    markers.forEach(marker => marker.setMap(null));
    markers = [];
  }

  const mapElement = document.getElementById('index-map');
  if (!mapElement) return;

  const customMapStyles = [
    { elementType: 'geometry', stylers: [{ color: '#f5f5f5' }] },
    { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
    { elementType: 'labels.text.fill', stylers: [{ color: '#000000' }] }, // Noms des villes en noir
    { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f5f5' }] },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#bdbdbd' }]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [{ color: '#eeeeee' }]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#e5e5e5' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#dadada' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }]
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }]
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [{ color: '#e5e5e5' }]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#92bae9' }] // Bleu pour les mers et océans
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }]
    },
    {
      featureType: 'landscape.natural.landcover', // Utilisation de beige pour les zones naturelles couvertes
      elementType: 'geometry',
      stylers: [{ color: '#f5f1ea' }]
    },
    {
      featureType: 'landscape.natural.terrain', // Utilisation de beige pour le terrain naturel
      elementType: 'geometry',
      stylers: [{ color: '#f5f1ea' }]
    }
  ];

  if (map) {
    map = null;
  }

  let zoomLevel = 6; // Zoom par défaut
  if (window.innerWidth <= 768) { // Largeur typique pour les appareils mobiles
    zoomLevel = 5; // Réduire le zoom pour les appareils mobiles
  }

  map = new google.maps.Map(mapElement, {
    center: { lat: 47.081012, lng: 2.398782 },
    zoom: zoomLevel,
    styles: customMapStyles,
  });

  setupAutocomplete();
  map.addListener('bounds_changed', function() {
    if (window.loadBicyclesDataTimeout) {
      clearTimeout(window.loadBicyclesDataTimeout);
    }
    window.loadBicyclesDataTimeout = setTimeout(loadBicyclesData, 500);
  });
  toggleDateAndDurationFields(mode === 'all');

  map.addListener('bounds_changed', debounce(handleBoundsChanged, 500));
}

let isSearchFormSubmitted = false;

document.addEventListener('DOMContentLoaded', function() {
  const searchForm = document.getElementById('search-form');
  if (searchForm) {
    searchForm.addEventListener('submit', function(event) {
      const startDate = document.getElementById('start-date-input').value;
      const endDate = document.getElementById('end-date-input').value;
      const category = document.getElementById('category-input').value;
      const spinner = document.getElementById('loading-spinner');
      spinner.style.display = 'flex';

      // Vérifiez d'abord si les dates sont renseignées
      if (mode === 'all') {
        // Aucune date ni catégorie
        if (!startDate && !endDate && !category) {
          event.preventDefault(); // Empêche la soumission du formulaire
          alert('Veuillez renseigner les dates ou sélectionner une catégorie.');
          spinner.style.display = 'none';
          return; // Stoppe l'exécution de la fonction ici
        }

        // Une seule date renseignée
        if ((startDate && !endDate) || (!startDate && endDate)) {
          event.preventDefault(); // Empêche la soumission du formulaire
          alert('Veuillez renseigner à la fois la date de début et la date de fin.');
          spinner.style.display = 'none';
          return; // Stoppe l'exécution de la fonction ici
        }
      }

      // Empêche la soumission normale du formulaire
      event.preventDefault();

      // Mettez à jour l'état de soumission du formulaire
      if (mode === 'all') {
        isSearchFormSubmitted = true;
      } else {
        isSearchFormSubmitted = false;
      }

      // Traite les données du formulaire
      const formData = new FormData(searchForm);
      searchParams = new URLSearchParams(formData).toString();
      mode = mode === 'all' ? 'filtered' : 'all';
      searchForm.querySelector('input[type="submit"]').value = mode === 'all' ? "Rechercher" : "Tout afficher";

      // Définir isSearchFormSubmitted à true si le mode est 'filtered'
      isSearchFormSubmitted = mode === 'filtered';

      sessionStorage.setItem('startDate', startDate);
      sessionStorage.setItem('endDate', endDate);
      sessionStorage.setItem('category', category);
      sessionStorage.setItem('page', 1);

      loadBicyclesData().then(() => {
        spinner.style.display = 'none'; // Masque le spinner après le chargement
      }).catch(error => {
        console.error('Erreur lors du chargement des vélos :', error);
        spinner.style.display = 'none'; // Masque le spinner même en cas d'erreur
      });
      
      toggleDateAndDurationFields(mode === 'all');
    });
  }
});  

function handleBoundsChanged() {
  const currentBounds = map.getBounds();
  console.log("Bounds changed");

  if (boundsChangedSignificantly(currentBounds)) {
    const loadMoreButton = document.getElementById('load-more');
  if (loadMoreButton) {
    lastBounds = currentBounds;
    loadBicyclesData(); // Make sure this function handles the reloading properly
    resetAndShowLoadMoreButton();
    }
  }
}

function boundsChangedSignificantly(newBounds) {
  if (!lastBounds) return true; // S'il n'y a pas de dernières limites, retourner true

  const changeThreshold = 10000; // Seuil de changement, ajustez selon les besoins
  const oldCenter = lastBounds.getCenter();
  const newCenter = newBounds.getCenter();

  if (google.maps.geometry && google.maps.geometry.spherical) {
    const distance = google.maps.geometry.spherical.computeDistanceBetween(oldCenter, newCenter);
    return distance > changeThreshold
  } else {
    console.error("Google Maps Geometry library is not loaded.");
    return false;
  }
}

function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export async function loadBicyclesData() {
  const spinner = document.getElementById('loading-spinner');
  spinner.style.display = 'flex';

  allBicycles = [];
  const startDate = document.getElementById('start-date-input').value;
  const endDate = document.getElementById('end-date-input').value;
  const category = document.getElementById('category-input').value;
  
  // Vérifier si la carte est disponible avant d'utiliser ses bounds
    const bounds = map.getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const boundsParams = `ne_lat=${ne.lat()}&ne_lng=${ne.lng()}&sw_lat=${sw.lat()}&sw_lng=${sw.lng()}`;

  let searchParams = '';
  if (category !== 'all') {
    searchParams += `bicycle_type=${category}`;
  }
  if (startDate && endDate) {
    searchParams += `&start_date=${startDate}&end_date=${endDate}`;
  }

  const jsonUrl = mode === 'all' ? `/bicycles.json?${boundsParams}` : `/bicycles_filtered.json?${searchParams}&${boundsParams}`;
  const htmlUrl = mode === 'all' ? `/bicycles?${boundsParams}` : `/bicycles_filtered?${searchParams}&${boundsParams}`;

  try {
    let page = 1;
    let totalBicycles = 0;
    let bicycles = [];

    // Charger toutes les annonces pour la carte
    do {
      const responseJson = await fetch(`${jsonUrl}&page=${page}`);
      const currentBicycles = await responseJson.json();
      console.log("Current :", currentBicycles)
      totalBicycles = parseInt(responseJson.headers.get('X-Total-Count'));
      console.log("Total count :", totalBicycles)

      if (currentBicycles.length === 0) break;

      currentBicycles.forEach(bike => {
        if (!allBicycles.some(existingBike => existingBike.id === bike.id)) {
          allBicycles.push(bike);
        }
      });

      page++;
    } while (allBicycles.length < totalBicycles);

    if (allBicycles.length === 0) {
      markers.forEach(marker => marker.setMap(null));
      markers = [];

      const message = "Aucun vélo disponible à ce lieu et à cette date... pour le moment !"; 
      const message_deux = "Essayez de rechercher une autre localisation ou d'autres dates ! N'hésitez pas à parler du site autour de vous afin de trouver votre bonheur la prochaine fois !";
      document.getElementById('bicycles-list').innerHTML = `<div class="text-center mt-5"><span><h4>${message}</h4></span>${message_deux}<p></div>`;
    } else {

      loadMarkers(allBicycles); // Mettez à jour les marqueurs pour tous les vélos chargés

      const responseHtml = await fetch(htmlUrl, {headers: {'Accept': 'text/html', 'X-Requested-With': 'XMLHttpRequest'}});
      const html = await responseHtml.text();
      document.getElementById('bicycles-list').innerHTML = html;
    }
    updateLoadMoreButtonVisibility(totalBicycles);
  } catch (error) {
    console.error('Error loading bicycles:', error);
    document.getElementById('bicycles-list').innerHTML = `<div><h4 class="text-center">Erreur lors du chargement des vélos. N'oubliez pas de sélectionner des dates afin de filtrer votre recherche !</h4></div>`;
  } finally {
    spinner.style.display = 'none';
  }
  toggleDateAndDurationFields(mode === 'all');
}

function updateLoadMoreButtonVisibility(totalBicycles) {
  const loadMoreButton = document.getElementById('load-more');
  if (!loadMoreButton) return;

    // Si la recherche par localisation est active, vérifiez le nombre de vélos chargés
    if (totalBicycles < 11) {
      loadMoreButton.style.display = 'none';
    } else {
      loadMoreButton.style.display = 'block';
    }
}

function buildBoundsParams() {
  const bounds = map.getBounds();
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  return `ne_lat=${ne.lat()}&ne_lng=${ne.lng()}&sw_lat=${sw.lat()}&sw_lng=${sw.lng()}`;
}

document.addEventListener('turbo:load', function() {
  const loadMoreButton = document.getElementById('load-more');

  if (loadMoreButton) {
    loadMoreButton.addEventListener('click', async function() {
      const button = this;
      const page = button.getAttribute('data-page');
      const boundsParams = buildBoundsParams(); // Assurez-vous que cette fonction construit correctement les paramètres
      const categoryFilter = isSearchFormSubmitted ? document.getElementById('category-input').value : '';

      const htmlUrl = `/bicycles?page=${page}&${boundsParams}${categoryFilter ? `&bicycle_type=${categoryFilter}` : ''}`;

      try {
        const responseHtml = await fetch(htmlUrl, {
          headers: { 'Accept': 'text/vnd.turbo-stream.html', 'X-Requested-With': 'XMLHttpRequest' }
        });
        const html = await responseHtml.text();
        document.getElementById('bicycles-list').insertAdjacentHTML('beforeend', html);

        console.log("X-Final-Page:", responseHtml.headers.get('X-Final-Page'));

        const isFinalPage = responseHtml.headers.get('X-Final-Page') === 'true';
        console.log("Is final page:", isFinalPage);

        const responseJson = await fetch(`/bicycles.json?page=${page}&${boundsParams}${categoryFilter ? `&bicycle_type=${categoryFilter}` : ''}`);
        const newBicycles = await responseJson.json();

        allBicycles = allBicycles.concat(newBicycles); // Ajoutez les nouveaux vélos à la liste globale
        updateLoadMoreButtonVisibility(responseHtml);

        const nextPage = parseInt(page) + 1;
        if (!isFinalPage) {
          button.setAttribute('data-page', nextPage);
          sessionStorage.setItem('page', nextPage);
        } else {
          button.style.display = 'none'; // Si c'est la dernière page, retirez le bouton
        }
      } catch (error) {
        console.error('Error loading more bicycles:', error);
      }
    });
  }
});

function toggleDateAndDurationFields(enable) {
  const startDateInput = document.getElementById('start-date-input');
  const endDateInput = document.getElementById('end-date-input');

  if (startDateInput && endDateInput) {
    startDateInput.disabled = !enable;
    endDateInput.disabled = !enable;
  }
}

function getCurrentPage() {
  const loadMoreButton = document.getElementById('load-more');
  if (loadMoreButton) {
    return parseInt(loadMoreButton.getAttribute('data-page')) || 1;
  }
  return 1; // Par défaut, retourne la première page si aucun bouton n'est trouvé
}

function getCurrentFilters() {
  const categoryInput = document.getElementById('category-input');
  const startDateInput = document.getElementById('start-date-input');
  const endDateInput = document.getElementById('end-date-input');

  const filters = {};

  if (categoryInput) {
    filters.category = categoryInput.value;
  }

  if (startDateInput) {
    filters.startDate = startDateInput.value;
  }

  if (endDateInput) {
    filters.endDate = endDateInput.value;
  }

  return filters;
}

function savePageState(bicycleId) {
  const currentState = {
    scrollTop: window.scrollY,
    currentPage: getCurrentPage(),
    filters: getCurrentFilters(),
    bicycleId: bicycleId // Ajoutez l'identifiant de l'annonce ici
    // Ajoutez d'autres informations d'état selon vos besoins
  };

  // Stockez l'état dans sessionStorage
  sessionStorage.setItem('savedPageState', JSON.stringify(currentState));
}

window.addEventListener('popstate', function(event) {
  // Restaurez l'état de la page depuis sessionStorage
  const savedState = JSON.parse(sessionStorage.getItem('savedPageState'));

  if (savedState) {
    // Restaurez la position du défilement
    window.scrollTo(0, savedState.scrollTop);

    // Restaurez la pagination et les filtres
    setCurrentPage(savedState.currentPage);
    applyFilters(savedState.filters);

    if (savedState.bicycleId) {
      const annonceItem = document.querySelector(`.annonce-item[data-bicycle-id="${savedState.bicycleId}"]`);
      if (annonceItem) {
        // Faites quelque chose avec l'annonce restaurée si nécessaire
      }
    }

    // Effacez l'état sauvegardé pour éviter de le réutiliser par erreur
    sessionStorage.removeItem('savedPageState');
  }
});

document.addEventListener('click', function(event) {
  // Vérifie si le clic est sur un élément qui représente une annonce
  const isAnnonceClick = event.target.closest('.annonce-item');
  
  if (isAnnonceClick) {
    const bicycleId = isAnnonceClick.dataset.bicycleId;
    // Vous pouvez maintenant enregistrer l'état actuel de la page
    savePageState(bicycleId);
  }
});